import yaml from 'js-yaml';
import {useEffect, useState} from "react";
import projectFile from '../data/projects.yaml';

function ProjectData() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch(projectFile)
            .then((response) => response.text())
            .then((text) => yaml.load(text))
            .then((data) => setProjects(data));
    }, []);

    return (
        <div className="flex flex-wrap">
            {projects.map((project, index) => (
                <div className="card w-96 bg-neutral-300 bg-opacity-30 shadow-xl m-4" key={index}>
                    <div className="card-body">

                        <div className="card-title">{project.name}</div>

                        <div className="card-body">{project.desc}</div>

                        <div className="card-actions justify-end">{project.techno.map((tech, index) => (

                            <div className="badge badge-outline" key={index}>{tech}</div>
                        ))}

                        </div>

                        <div className="card-actions justify-end">{project.skill.map((sk, index) => (

                            <div className="badge badge-outline" key={index}>{sk}</div>
                        ))}

                        </div>

                    </div>


                </div>
            ))
            }
        </div>
    );
}


export default ProjectData;