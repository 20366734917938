
const SkillTable = () => {
    return (
        <ul>
            <li>
                <div className="font-bold">B1.1 - Gérer le patrimoine informatique</div>
                <ul className="ml-8">
                    <li>Recenser et identifier les ressources numériques</li>
                    <li>Exploiter des référentiels, normes et standards adoptés par le prestataire informatique</li>
                    <li>Mettre en place et vérifier les niveaux d’habilitation associés à un service</li>
                    <li>Vérifier les conditions de la continuité d’un service informatique</li>
                    <li>Gérer des sauvegardes</li>
                    <li>Vérifier le respect des règles d’utilisation des ressources numériques</li>
                </ul>
            </li>
            <li>
                <div className="font-bold">B1.2 - Répondre aux incidents et aux demandes d’assistance et d’évolution</div>
                <ul className="ml-8">
                    <li>Collecter, suivre et orienter des demandes</li>
                    <li>Traiter des demandes concernant les services réseau et système, applicatifs</li>
                    <li>Traiter des demandes concernant les applications</li>
                </ul>
            </li>
            <li>
                <div className="font-bold">B1.3 - Développer la présence en ligne de l’organisation</div>
                <ul className="ml-8">
                    <li>Participer à la valorisation de l’image de l’organisation sur les médias numériques en tenant compte du cadre juridique et des enjeux économiques</li>
                    <li>Référencer les services en ligne de l’organisation et mesurer leur visibilité.</li>
                    <li>Participer à l’évolution d’un site Web exploitant les données de l’organisation.</li>
                </ul>
            </li>
            <li>
                <div className="font-bold">B1.4 - Travailler en mode projet</div>
                <ul className="ml-8">
                    <li>Analyser les objectifs et les modalités d’organisation d’un projet</li>
                    <li>Planifier les activités</li>
                    <li>Évaluer les indicateurs de suivi d’un projet et analyser les écarts</li>
                </ul>
            </li>
            <li>
                <div className="font-bold">B1.5 -  Mettre à disposition des utilisateurs un service informatique</div>
                <ul className="ml-8">
                    <li>Réaliser les tests d’intégration et d’acceptation d’un service</li>
                    <li>Déployer un service</li>
                    <li>Accompagner les utilisateurs dans la mise en place d’un service</li>
                </ul>
            </li>
            <li>
                <div className="font-bold">B1.6 -  Organiser son développement professionnel</div>
                <ul className="ml-8">
                    <li>Mettre en place son environnement d’apprentissage personnel</li>
                    <li>Mettre en œuvre des outils et stratégies de veille informationnelle</li>
                    <li>Gérer son identité professionnelle</li>
                    <li>Développer son projet professionnel</li>
                </ul>
            </li>
        </ul>
    )
}

export default SkillTable;