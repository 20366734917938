import yaml from 'js-yaml';
import {useEffect, useState} from "react";
import experienceFile from '../data/experiences.yaml';

function ExperienceData() {
    const [experiences, setProjects] = useState([]);

    useEffect(() => {
        fetch(experienceFile)
            .then((response) => response.text())
            .then((text) => yaml.load(text))
            .then((data) => setProjects(data));
    }, []);

    return <>
        <ul className="timeline timeline-vertical">
            {experiences.map((experience, index) => (
                <li key={index}>
                    <hr/>
                    <div className={`timeline-${index % 2 === 0 ? 'start' : 'end'} timeline-box m-4 bg-neutral-300 bg-opacity-30 border-none`}>
                        <div className="text-2xl">
                            {experience.name}
                        </div>
                        <div className="text-xl">
                            {experience.location}
                        </div>
                        <div>
                            {experience.type}
                        </div>
                        <div>
                            {experience.dateDebut} - {experience.dateFin}
                        </div>
                    </div>
                    <hr/>
                </li>
            ))}
        </ul>
    </>;
}


export default ExperienceData;