import yaml from 'js-yaml';
import { useEffect, useState } from 'react';
import skillFile from '../data/skills.yaml';
import {SkillSvg} from "../componants.js";

function SkillData() {
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        fetch(skillFile)
            .then((response) => response.text())
            .then((text) => yaml.load(text))
            .then((data) => setSkills(data));
    }, []);

    const rawTypes = skills.map(skill => skill.type);
    const types = rawTypes.filter((type, index) => rawTypes.indexOf(type) === index);
    console.log(types);

    return (
        <div className="flex justify-around w-100">
                {types.map((type, index) => (
                    <div className="w-48" key={index}>
                        <div className="font-bold text-3xl text-center mb-12">{type.toUpperCase()}</div>
                        {skills.filter(skill => skill.type === type).map((skill, index) => (
                            <div>
                                <div key={index} className="flex justify-between my-6">
                                    <span><SkillSvg  path={"media/logo/" + skill.svg + ".svg"} size="36"/></span>
                                    <div className="items-center flex">{skill.name}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
        </div>
    );
}

export default SkillData;
